import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { _APIS, CallApi } from "./common/API/APi";
import { success, error } from "./common/Toastify";
import logo from "../assets/images/main-logo.png";
import car from "../assets/images/cloud-cars.png";
import { ToastContainer } from "react-toastify";

const Otp = () => {
  const [errors, setError] = useState({});
  const [otpnum, setOtpnum] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
    const { email } = location.state || {};
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (pasteData.length === 4 && /^[0-9]{4}$/.test(pasteData)) {
      setOtpnum({
        first: pasteData[0],
        second: pasteData[1],
        third: pasteData[2],
        fourth: pasteData[3],
      });
    }
  };

  const SubmitOtp = async () => {
    if (!otpnum?.first) {
      setError({ first: true });
      return;
    }
    if (!otpnum?.second) {
      setError({ second: true });
      return;
    }
    if (!otpnum?.third) {
      setError({ third: true });
      return;
    }
    if (!otpnum?.fourth) {
      setError({ fourth: true });
      return;
    }
     if(!email){
      error("you can't logged in!");
     }
    setError({});
    let otpstring =
      otpnum?.first + otpnum?.second + otpnum?.third + otpnum?.fourth;
    let res = await _APIS({ otp: parseInt(otpstring),email:email }, "otp");
    if (res?.status === 200) {
      localStorage.setItem("token", res?.token);
      success(res?.message);
      ProfileData();
    } else {
      error(res?.message);
    }
  };

  useEffect(() => {
    const inputs = document.querySelectorAll(".otp-com-input");
    if (inputs?.length) {
      inputs.forEach((input, index) => {
        if (index < 3) {
          input.addEventListener("input", function () {
            if (input.value.trim() !== "") {
              const nextIndex = (index + 1) % inputs.length;
              const nextInput = inputs[nextIndex];
              nextInput.focus();
            }
          });
        }
      });
    }
  }, [otpnum]);

  const ProfileData = async () => {
    let res = await CallApi({}, "profile");
    let filepath = process.env.REACT_APP_PATH_URL;
    if (res?.status === 200) {
      localStorage.setItem(
        "profiledata",
        JSON.stringify({
          permission: res?.data?.permission,
          name: res?.data?.name,
          rolename: res?.data?.rolename,
          id: res?.data?.id,
          img: filepath + res?.data?.profile_image,
          user_type: res?.data?.user_type,
        })
      );
      if (res?.data?.user_type == 1) {
        res?.data?.permission
          .filter((e) => e.name === "Dashboard")[0]
          ?.permission?.view === 1
          ? navigate("/dashboard")
          : navigate("/profile");
      } else {
        navigate("/bookings");
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="form-design main-page d-flex align-items-center">
        <img className="w-100 h-100 form-design-bg" src={car} alt="black cars" />
        <div className="form-design-inner position-relative mx-auto row">
          <div className="form-design-inner-left d-flex align-items-center justify-content-center col-md-5 position-relative">
            <img src={logo} className="d-block mx-auto" alt="main-logo" />
            <div className="dot rounded-circle position-absolute"></div>
          </div>
          <div className="form-design-inner-right d-flex align-items-center justify-content-center col-md-7 position-relative">
            <div className="dot rounded-circle position-absolute"></div>
            <div className="form-design-inner-right-inner w-100 text-center">
              <h1 className="form-design-inner-right-inner-heading mb-5">Verify OTP</h1>
              <div className="form-design-inner-right-inner-input mb-4 justify-content-between">
                <input
                  type="text"
                  pattern="\d*"
                  maxLength="1"
                  value={otpnum.first}
                  onChange={(e) =>
                    setOtpnum({ ...otpnum, first: e.target.value })
                  }
                  onPaste={handlePaste}
                  className={
                    errors?.first
                      ? " font-13px pe-2 otp-com-input border-danger"
                      : "font-13px pe-2 otp-com-input"
                  }
                />
                <input
                  type="text"
                  pattern="\d*"
                  maxLength="1"
                  value={otpnum.second}
                  onChange={(e) =>
                    setOtpnum({ ...otpnum, second: e.target.value })
                  }
                  className={
                    errors?.second
                      ? " font-13px pe-2 otp-com-input border-danger"
                      : "font-13px pe-2 otp-com-input"
                  }
                />
                <input
                  type="text"
                  pattern="\d*"
                  maxLength="1"
                  value={otpnum.third}
                  onChange={(e) =>
                    setOtpnum({ ...otpnum, third: e.target.value })
                  }
                  className={
                    errors?.third
                      ? " font-13px pe-2 otp-com-input border-danger"
                      : "font-13px pe-2 otp-com-input"
                  }
                />
                <input
                  type="text"
                  pattern="\d*"
                  maxLength="1"
                  value={otpnum.fourth}
                  onChange={(e) =>
                    setOtpnum({ ...otpnum, fourth: e.target.value })
                  }
                  className={
                    errors?.fourth
                      ? " font-13px pe-2 otp-com-input border-danger"
                      : "font-13px pe-2 otp-com-input"
                  }
                />
              </div>
              <button
                className="btn form-design-inner-right-inner-btn golden-bg mt-5"
                onClick={SubmitOtp}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
